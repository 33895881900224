<template>
  <div>
    <v-toolbar dense flat>
      <v-chip
        v-for="(header, index) in availableHeaders"
        :key="index"
        small
        class="mx-1"
        @click="addHeader(header)"
      >
        {{ header.text }} <v-icon color="blue" small right>add</v-icon>
      </v-chip>
    </v-toolbar>
    <table
      :key="productKey"
      style="width: 100%; max-height: 30vh; overflow-y: auto"
    >
      <thead style="position: sticky; width: 100%">
        <draggable
          v-model="productSummaryHeaders"
          tag="tr"
          :style="{ cursor: columnDrag ? 'grabbing' : 'grab' }"
          :animation="200"
          ghost-class="ghost-card"
          @start="columnDrag = true"
          @end="columnDrag = false"
          @change="updateHeaderSelection()"
        >
          <th
            class="text-center"
            style="cursor: grab"
            v-for="(header, index) in productSummaryHeaders"
            :key="index"
          >
            <b style="font-size: 11px">{{ header.text }}</b>
            <v-btn
              icon
              small
              class="ml-1"
              @click="removeHeader(header.value)"
              v-if="!header.required"
            >
              <v-icon small>close</v-icon>
            </v-btn>
          </th>
        </draggable>
      </thead>
      <tbody style="height: 50vh; overflow-y: auto">
        <tr v-for="(item, i) in groupedItems" :key="i">
          <template v-for="(header, index) in productSummaryHeaders">
            <td
              :key="index"
              style="font-size: 11px"
              v-if="
                header.value != 'cartonPrice' &&
                header.value != 'kgPrice' &&
                item[header.value] != null
              "
              :style="{ border: '0.4px solid rgb(95, 95, 95)' }"
              :rowspan="
                item[header.value + '_rowspan']
                  ? item[header.value + '_rowspan']
                  : 1
              "
              class="text-center"
            >
              <v-btn x-small class="mr-1" style="text-transform: none" text>
                {{ item[header.value] }}
              </v-btn>
            </td>

            <td
              style="font-size: 11px"
              v-else-if="item[header.value] != null"
              :key="index"
              class="text-center"
            >
              <v-icon color="amber">lock</v-icon>
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import draggable from "vuedraggable";

export default {
    components: {
        draggable
    },
  props: {
    allHeaders: {
      type: Array,
      required: true,
    },
    selectedHeaders: {
      type: Array,
      required: true,
        },
    data: {
      type: Array,
        required: true,
      default: ()=>[]
    },
  },
  data: () => ({
    columnDrag: false,
    groupedItems: [],
    productSummaryHeaders: [],
    productKey: 0,
    groupBy: [
      "sscc"
    ],
  }),
    watch: {
        data: {
        handler(newVal) {
            console.log(newVal)
                this.groupData();
        },
        deep: true,
        immediate: true,
    },
    selectedHeaders: {
      handler(newVal) {
            this.productSummaryHeaders = newVal;
        console.log("Selected headers", newVal)
      },
      deep: true,
      immediate: true,
    },
    },
    computed: {
        availableHeaders() {
      return this.allHeaders.filter(
        (x) => !this.productSummaryHeaders.map((y) => y.text).includes(x.text)
      );
    },
  },
  methods: {
    addHeader(header) {
      header.show = true;
      this.productSummaryHeaders.unshift(header);
      this.updateSelectedHeaders();
    },
    removeHeader(header) {
      let index = this.productSummaryHeaders.findIndex(
        (x) => x.value == header
      );
        this.productSummaryHeaders[index].show = false;
      this.groupBy = this.groupBy.filter((x) => x != header.value);
      this.updateSelectedHeaders();
    },
      constructGroups() {
        // this.groupBy = this.groupBy.filter(x=>this.productSummaryHeaders.map(y=>y.value).includes(x))
      let groupBy = [...this.groupBy];
      let result = [];
      let initialCount = groupBy.length;
      while (initialCount > 0) {
        let group = groupBy.slice(0, initialCount);
        result.push(group);
        initialCount--;
      }
      return result;
      },
      formatObjects(result) {
      if (this.groupBy.includes("productId")) {
        result = result.map((item) => {
          if (item.productId) {
            // let findProduct = this.products.find(
            //   (product) => product.id == item.productId
            // );
            item.productId = item.product ? item.product.name : null;
          }
          return item;
        });
      }
      if (this.groupBy.includes("varietyProductId")) {
        result = result.map((item) => {
          if (item.varietyProductId) {
            item.varietyProductId = item.varietyProduct ? item.varietyProduct.name : null;
          }
          return item;
        });
      }
      return result;
    },
      getHeaderDetail(group) {
      switch (group) {
        case "phc":
          return {
            text: "Packhouse",
            value: "phc",
            align: "center",
            initialValue: group,
          };
        case "productId":
          return {
            text: "Product",
            value: "product",
            align: "center",
            initialValue: group,
          };
        case "varietyProductId":
          return {
            text: "Variety",
            value: "varietyProduct",
            align: "center",
            initialValue: group,
          };
        case "packCode":
          return {
            text: "Packcode",
            value: "packCode",
            align: "center",
            initialValue: group,
          };
        case "grade":
          return {
            text: "Grade",
            value: "grade",
            align: "center",
            initialValue: group,
          };
        case "countSize":
          return {
            text: "Count",
            value: "countSize",
            align: "center",
            initialValue: group,
          };
        case "status":
          return {
            text: "status",
            value: "Status",
            align: "center",
            initialValue: group,
          };
        case "cartonQuantity":
          return {
            text: "No. Cartons",
            value: "cartonQuantity",
            align: "center",
            initialValue: group,
          };
        case "ctoNo":
          return {
            text: "CTO No.",
            value: "ctoNo",
            align: "center",
            initialValue: group,
          };
        case "containerNo":
          return {
            text: "Container No.",
            value: "containerNo",
            align: "center",
            initialValue: group,
          };
        default:
          return {
            text: group,
            value: group,
            align: "center",
            initialValue: group,
          };
      }
    },
    groupData() {
      let pallets = JSON.parse(
        JSON.stringify(
            this.data
        )
      );
      let result = [];
        let groups = this.constructGroups();
      console.log("Groups", groups)
      groups.reverse();
      let uniqueGroups = [];
      let total = pallets.reduce((a, b) => a + parseInt(b.noCartons), 0);
      let maxLevel = 0;
      for (let i = 0; i < groups.length; i++) {
        for (let j = 0; j < pallets.length; j++) {
          if (!pallets[j].groups) {
            pallets[j].groups = [];
          }
          let values = groups[i].map((x) => pallets[j][x]);
          let hash = values.join(":");
          pallets[j].groups.push(hash);
          if (!uniqueGroups.includes(hash)) {
            uniqueGroups.push(hash);
          }
        }
        let productResult = [];
        for (let j = 0; j < uniqueGroups.length; j++) {
          let groupPallets = pallets.filter(
            (x) => x.groups[i] && x.groups[i] == uniqueGroups[j]
          );
          if (groupPallets.length > 0) {
            let detail = groupPallets[0];
            let parent = null;
            if (i > 0) {
              parent = detail.groups[i - 1];
            }
            maxLevel = i + 1;
            let obj = {
              level: i + 1,
              hash: uniqueGroups[j],
              parent: parent,
              cartonQuantity: groupPallets.reduce(
                (a, b) => a + parseInt(b.noCartons),
                0
              ),
              palletQuantity:
                Math.round(
                  groupPallets.reduce(
                    (a, b) => a + parseFloat(b.palletQuantity),
                    0
                  ) * 100
                ) / 100,
              availableQuantity:
                groupPallets.reduce(
                  (a, b) => a + parseInt(b.noCartons),
                  0
                ),
              percentage:
                Math.round(
                  (groupPallets.reduce(
                    (a, b) => a + parseInt(b.noCartons),
                    0
                  ) /
                    total) *
                    100 *
                    100
                ) /
                  100 +
                "%",
            };
            // console.log(groupPallets.reduce((a, b) => a + parseInt(b.palletQuantity), 0), groupPallets.map(b=>parseInt(b.palletQuantity)))
            for (let k = 0; k < groups.at(-1).length; k++) {
              let findGroup = this.getHeaderDetail(groups.at(-1)[k]);
              obj[findGroup.initialValue] = detail[findGroup.initialValue];
              if (!obj[findGroup.initialValue]) {
                obj[findGroup.initialValue] = "(none)";
              }
            }
            productResult.push(obj);
          }
        }
        result = [...result, ...productResult];
      }
      console.log(result)
      for (let i = 0; i < result.length; i++) {
        result[i].children = result.filter(
          (x) => x.parent == result[i].hash
        ).length;
      }
      result = result.filter((x) => x.level == maxLevel);
      result.sort((a, b) =>
        a.parent > b.parent ? 1 : b.parent > a.parent ? -1 : 0
      );
      let fields = groups
        .at(-1)
        .slice(0, groups.length - 1)
        .map((x) => this.getHeaderDetail(x).initialValue);
      // Sort data
      for (let i = 0; i < fields.length; i++) {
        let filteredFields = fields.filter((x, index) => index <= i);
        for (let j = 0; j < result.length; j++) {
          if (!result[j].parentHash) {
            result[j].parentHash = [];
          }
          if (!result[j].referenceHash) {
            result[j].referenceHash = [];
          }
          let values = filteredFields.map((x) => result[j][x]);
          let hash = values.join(":");
          result[j].parentHash.push(hash);
          result[j]["referenceHash" + "_" + i] = result[j].parentHash.join("_");
          result[j].referenceHash.push(result[j].parentHash.join("_"));
        }
      }
      let sortArray = result;
      let initialAlphabet = [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
      ];
      let alphabet = [];
      for (let i = 0; i < 10; i++) {
        let lastLetter = i > 0 ? initialAlphabet[i - 1] : "";
        initialAlphabet.forEach((x) => {
          alphabet.push(lastLetter + x);
        });
      }
      for (let i = 0; i < groups.length - 1; i++) {
        let uniqueReferences = [
          ...new Set(result.map((x) => x.referenceHash[i])),
        ];
        for (let j = 0; j < uniqueReferences.length; j++) {
          let filter = result.filter(
            (x) => x.referenceHash[i] == uniqueReferences[j]
          );
          filter.sort((a, b) =>
            a[fields[i]] > b[fields[i]]
              ? 1
              : b[fields[i]] > a[fields[i]]
              ? -1
              : 0
          );
          for (let k = 0; k < filter.length; k++) {
            let findIndex = sortArray.findIndex(
              (x) => x.hash == filter[k].hash
            );
            if (!sortArray[findIndex]["Index"]) {
              sortArray[findIndex]["Index"] = [];
            }
            sortArray[findIndex]["Index"].push(alphabet[j]);
            sortArray[findIndex]["Index_" + i] = alphabet[j];
          }
        }
      }
      console.log(sortArray)
      sortArray = sortArray.map((x) => ({ ...x, index: x.Index.join("") }));
      sortArray.sort((a, b) =>
        a.index > b.index ? 1 : b.index > a.index ? -1 : 0
      );
      result = sortArray;
      let uniqueParents = [...new Set(result.map((x) => x.parent))];
      let finalResult = [];
      for (let i = 0; i < uniqueParents.length; i++) {
        let filtered = result.filter((x) => x.parent == uniqueParents[i]);
        for (let j = 0; j < fields.length; j++) {
          let uniqueValues = [...new Set(filtered.map((x) => x[fields[j]]))];
          for (let k = 0; k < uniqueValues.length; k++) {
            let firstIndex = filtered.findIndex(
              (x) => x[fields[j]] == uniqueValues[k]
            );
            for (let l = 0; l < filtered.length; l++) {
              if (l == firstIndex) {
                filtered[l][fields[j]] = uniqueValues[k];
                filtered[l][fields[j] + "_rowspan"] = filtered.length;
              } else {
                filtered[l][fields[j]] = null;
              }
            }
          }
        }
        finalResult = [...finalResult, ...filtered];
      }
      for (let i = 0; i < fields.length; i++) {
        let indices = [...new Set(finalResult.map((x) => x["Index_" + i]))];
        for (let j = 0; j < indices.length; j++) {
          let values = [
            ...new Set(
              finalResult
                .filter((x) => x["Index_" + i] == indices[j])
                .map((x) => x[fields[i]])
                .filter(Boolean)
            ),
          ];
          for (let k = 0; k < values.length; k++) {
            let findFirstIndex = finalResult.findIndex(
              (x) => x["Index_" + i] == indices[j] && x[fields[i]] == values[k]
            );
            let filter = finalResult.filter(
              (x) => x["Index_" + i] == indices[j] && x[fields[i]] == values[k]
            );
            for (let l = 0; l < filter.length; l++) {
              let filterIndex = finalResult.findIndex(
                (x) => x.hash == filter[l].hash
              );
              if (filterIndex != findFirstIndex) {
                finalResult[filterIndex][fields[i]] = null;
                finalResult[filterIndex][fields[i] + "_rowspan"] = null;
              } else {
                finalResult[filterIndex][fields[i] + "_rowspan"] = filter
                  .map((x) => x[fields[i] + "_rowspan"])
                  .filter(Boolean)
                  .reduce((a, b) => a + b);
              }
            }
          }
        }
      }
        // finalResult = this.formatObjects(finalResult);
      console.log(finalResult)
      this.groupedItems = finalResult;
    },
    updateSelectedHeaders() {
      this.productSummaryHeaders = this.allHeaders.filter(
        (x) => x.show || x.required
      );
      this.updateHeaderSelection();
      this.productKey++;
    },
    updateHeaderSelection() {
      this.groupBy = this.productSummaryHeaders
        .filter((x) => x.group && x.show)
        .map((x) => x.value);
      this.productKey++;
      this.groupData();
    },
  },
};
</script>